import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export class Reviews {
    reviews = document.querySelectorAll('.js-review');

    constructor() {
        gsap.registerPlugin(ScrollTrigger);
        this.reviews.forEach(review => {

            const button = review.querySelector('.js-reviews-more');

            button.addEventListener('click', () => {
                review.classList.add('reviews__item--collapsed');
                gsap.to(button, {
                    autoAlpha: 0
                })
            })

            gsap.set(review, {
                opacity: 0,
                yPercent: 50,
            })
            gsap.to(review, {
                opacity: 1,
                yPercent: 0,
                scrollTrigger: {
                    trigger: review
                }
            })
        })
    }
}