import gsap from "gsap";

export class Work {
    triggers = document.querySelectorAll('.js-work-item');
    wrapper = document.getElementById('modals');
    modalClose = document.getElementById('modalClose');
    modals = gsap.utils.toArray(document.querySelectorAll('.js-modal'));
    modalsContent = gsap.utils.toArray(document.querySelectorAll('.js-modal-content'));

    gallery = document.getElementById('gallery');
    galleryItems = gsap.utils.toArray(document.querySelectorAll('.js-gallery-item'));
    galleryContent = document.getElementById('galleryContent');
    galleryClose = document.getElementById('galleryClose');

    constructor() {
        this.initTriggers();
    }

    initTriggers() {
        gsap.set(this.wrapper, {
            yPercent: 100,
            // autoAlpha: 0
        })

        gsap.set(this.gallery, {
            autoAlpha: 0,
            // scale: 0,
        })

        this.triggers.forEach(trigger => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();
                this.showModal(trigger.getAttribute('data-href'));
            })
        })

        this.galleryItems.forEach(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                this.showGallery(item.getAttribute('href'))
            })
        })

        this.modals.forEach(modal => {
            modal.addEventListener('click', (e) => {
                this.hideModals();
            })
        })

        this.modalsContent.forEach(content => {
            content.addEventListener('click', (e) => {
                e.stopPropagation();
            });
        })

        this.modalClose.addEventListener('click', () => {
            this.hideModals();
        })

        this.galleryClose.addEventListener('click', () => {
            this.hideGallery();
        })

        this.galleryContent.addEventListener('click', () => {
            this.hideGallery();
        })
    }

    hideModals() {
        document.body.style.overflowY = 'unset';
        gsap.to(this.wrapper, {
            yPercent: 100,
            autoAlpha: 0
        })
    }

    showModal(modalId) {
        document.body.style.overflowY = 'hidden';

        const index = this.modals.findIndex(modal => modal.getAttribute('id') === modalId);

        gsap.set(this.modalClose, {
            left: `calc(${index * 100}vw + 12px)`,
            duration: 0
        })

        gsap.timeline()
            .to(this.wrapper, {
                yPercent: 0,
                autoAlpha: 1,
            }).to(this.wrapper, {
                x: `-${index * 100}vw`
            })

    }

    hideGallery() {
        gsap.to(this.gallery, {
            // scale: 0,
            autoAlpha: 0
        })
    }

    showGallery(src) {
        this.galleryContent.src = src;

        gsap.to(this.gallery, {
            // scale: 1,
            autoAlpha: 1,
            duration: 0.2
        })
    }
}