import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export class Services {
  wrapper = document.getElementById('services');
  slider = document.getElementById('servicesSlider');
  page = document.querySelector('.js-scrollable');
  items = document.querySelectorAll('.js-services-item');
  dots = document.querySelectorAll('.js-services-dot');

  current = 0;

  start = 0;
  end = 0;

  dragging = false;

  constructor() {
    // this.initSlider();
    this.render();

    this.initEvents();
    // this.initGsap();
  }

  initGsap() {
    // gsap.registerPlugin(ScrollTrigger);

    // ScrollTrigger.matchMedia({
    //   "(min-width: 768px)": () => {
    //     this.current = 0;
    //     this.render();

    //     gsap.to(this.items, {
    //       xPercent: -100 * (this.items.length - 1),
    //       // ease: 'none',
    //       duration: 1,
    //       scrollTrigger: {
    //         trigger: this.page,
    //         pin: this.page,
    //         // markers: true,
    //         scrub: true,
    //         end: `+=${this.slider.offsetWidth}`,
    //       }
    //     })
    //   }
    // })
  }

  initSlider() {
    if (!this.slider) {
      return;
    }

    this.slider.style.gridTemplateColumns = Array.from(this.items)
      .map(() => '1fr')
      .join(' ');
  }

  cancelEvents() {
    removeEventListener('touchstart', this.wrapper);
    removeEventListener('touchend', this.wrapper);
    removeEventListener('mousedown', this.wrapper);
    removeEventListener('mousemove', this.wrapper);
    removeEventListener('mouseup', this.wrapper);
  }

  initEvents() {
    if (!this.wrapper) {
      return;
    }

    this.wrapper.addEventListener('touchstart', (e) => {
      this.start = e.changedTouches[0].screenX;
    });

    this.wrapper.addEventListener('touchend', (e) => {
      this.end = e.changedTouches[0].screenX;
      this.handleGesture();
    });

    this.wrapper.addEventListener('mousedown', (e) => {
      this.dragging = true;
      this.start = e.screenX;
    });

    this.wrapper.addEventListener('mousemove', (e) => {
      if (!this.dragging) return;
      this.end = e.screenX;
    });

    this.wrapper.addEventListener('mouseup', (e) => {
      this.dragging = false;
      this.handleGesture();
    });
  }

  handleGesture() {
    if (window.innerWidth > 768) {
      return;
    }

    if (this.end < this.start) {
      this.onSwipeLeft();
    }

    if (this.end > this.start) {
      this.onSwipeRight();
    }
  }

  onSwipeRight() {
    if (this.current === 0) return;

    this.current--;
    this.render();
  }

  onSwipeLeft() {
    if (this.current === this.items.length - 1) return;

    this.current++;
    this.render();
  }

  render() {
    if (!this.slider) {
      return;
    }

    this.slider.style.transform = `translateX(-${(this.current * 100) / this.items.length
      }%)`;

    this.items.forEach((item, index) => {
      if (index === this.current) {
        item.classList.add('services__item--active');
      } else {
        item.classList.remove('services__item--active');
      }
    });

    this.dots.forEach((dot, index) => {
      if (index === this.current) {
        dot.classList.add('active');
      } else {
        dot.classList.remove('active');
      }
    });
  }
}
