import { Footer } from './footer';
import { Header } from './header';
// import { Partners } from './partners';
import { Reviews } from './reviews';
import { Services } from './services';
import { Work } from './work';

const init = () => {
  new Services();
  // new Partners();
  new Reviews();
  new Work();
  new Header();

  // const locale = document.getElementsByTagName('html')[0].getAttribute('lang');
  // new Footer(locale);
  new Footer();
};

document.addEventListener('DOMContentLoaded', init);
