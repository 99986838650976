import gsap from "gsap/all";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class Header {
    menuToggle = document.getElementById('menuToggle');
    nav = document.getElementById('nav');
    navItems = document.querySelectorAll('#nav a');

    defaultIcon = document.querySelector('.js-header__icon--default');
    collapsedIcon = document.querySelector('.js-header__icon--collapsed');

    isCollapsed = false;

    constructor() {
        this.initEvents();
    }

    initEvents() {
        document.body.style.overflowY = 'unset';
        gsap.set(this.collapsedIcon, { opacity: 0, rotate: 360 });
        gsap.set(this.nav, { xPercent: 100 });

        // window.addEventListener('resize', () => {
        //     this.hideMenu();
        // })

        this.menuToggle.addEventListener('click', () => {
            if (this.isCollapsed) {
                this.hideMenu();
            } else {
                this.showMenu();
            }
        });

        this.navItems.forEach(item => {
            item.addEventListener('click', () => {
                this.hideMenu();
            });
        })
    }

    showMenu() {
        this.isCollapsed = true;
        this.render();
    }

    hideMenu() {
        this.isCollapsed = false;
        this.render();
    }

    render() {
        if (window.innerWidth > 768) {
            document.body.style.overflowY = 'unset';
            gsap.set(this.nav, { xPercent: 0 });

            return;
        }

        if (this.isCollapsed) {
            document.body.style.overflowY = 'hidden';
            gsap.fromTo(this.nav, { duration: 0.5, xPercent: 100 }, { xPercent: 0 });

            gsap.to(this.defaultIcon, { opacity: 0, rotate: 360 });
            gsap.to(this.collapsedIcon, { opacity: 1, rotate: 0 });

            gsap.from(this.navItems, {
                delay: 0.6,
                transition: 'ease-in',
                opacity: 0,
                duration: 0.3,
                x: 100,
                stagger: 0.2
            });
        } else {
            document.body.style.overflowY = 'unset';
            gsap.fromTo(this.nav, { duration: 0.5, xPercent: 0 }, { xPercent: 100 });

            gsap.to(this.defaultIcon, { opacity: 1, rotate: 0 });
            gsap.to(this.collapsedIcon, { opacity: 0, rotate: 360 });
        }
    }
}