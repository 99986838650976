import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import localeRu from 'dayjs/locale/ru';
import localeKk from 'dayjs/locale/kk';
import localeEn from 'dayjs/locale/en';

const TIMEZONE = 'Asia/Almaty';
const INTERVAL = 1000;

export class Footer {
  time = document.getElementById('footerTime');
  format = 'HH:mm:ss a';

  // constructor(locale) {
  constructor() {
    // this.setLocale(locale);

    dayjs.extend(utc);
    dayjs.extend(timezone);

    setInterval(() => {
      this.render();
    }, INTERVAL);
  }

  // setLocale(locale) {
  //   switch (locale) {
  //     case 'ru':
  //       dayjs.locale(localeRu);
  //       break;
  //     case 'en':
  //       dayjs.locale(localeEn);
  //       break;
  //     case 'kk':
  //       this.format = 'HH:mm';
  //       dayjs.locale(localeKk);
  //       break;
  //   }
  // }

  render() {
    this.time.innerHTML = `UTC+06:00, ${dayjs().tz(TIMEZONE).format(this.format)}`;
  }
}
